import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Context, createWrapper } from 'next-redux-wrapper';
import { getInitialEmailMarketingConsentState } from 'NewAccountForm/newAccountFormState/emailMarketingConsentSlice';
import getCookies from 'lib/dataFetchers/getCookies';
import { AppContext } from 'next/app';
import { defaultInitialCheckoutState } from 'CheckoutPage/state/checkoutState';
import { rootReducer } from './rootReducer';
import { RootState } from './RootState';
import { AppDispatch } from './appDispatch';

const initialState = {};
// Make Store will invoked with a Next.js Context which
// could be NextPageContext or AppContext or getStaticProps or getServerSideProps.
// See https://github.com/kirill-konshin/next-redux-wrapper
// In practice, we only use this with AppContext
// Since our redux store is only initialized in _app
export const makeStore = (
  context: Context,
  { state = initialState }: { state: Partial<RootState> }
): Store<RootState, AnyAction> & {
  dispatch: AppDispatch;
} => {
  const cookies = getCookies((context as AppContext).ctx);
  const countryIsoAlpha2 = (('ctx' in context &&
    context?.ctx?.req?.headers['cf-ipcountry']) ||
    undefined) as string | undefined;
  const store: Store<RootState, AnyAction> & {
    dispatch: AppDispatch;
  } = createStore(
    rootReducer,
    {
      ...state,
      CheckoutHandler: {
        ...defaultInitialCheckoutState,
        country: countryIsoAlpha2 ?? '',
        ...state.CheckoutHandler,
      },
      emailMarketingConsent: {
        ...getInitialEmailMarketingConsentState(countryIsoAlpha2),
      },
    },
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware.withExtraArgument({
          cookies,
        })
      )
    )
  );
  return store;
};

const reduxWrapper = createWrapper(
  (context: Context) => makeStore(context, { state: {} }),
  { debug: false }
);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default reduxWrapper;
