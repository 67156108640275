import { gql } from '@apollo/client';

/**
 * Fetches session data via graphql.
 *
 * @param {object} apolloClient: An object representing the Apollo Client which enables
 * graphQL queries.
 *
 * @param {string} sessionId: A string representing the session id passed in
 * via the query string.
 *
 * @param {string} sessionHash: A string representing the session hash passed in
 * via the query string.
 *
 */
const fetchTaxSessionData = async (apolloClient, sessionId, sessionHash) => {
  try {
    const GET_TAX_SESSION_INFO = gql`
      query getTaxSessionData($sessionId: String!, $sessionHash: String!) {
        session(id: $sessionId, hash: $sessionHash) {
          __typename
          ... on Session {
            taxContent
          }

          ... on GqlErrorInterface {
            message
          }
        }
      }
    `;
    const results = await apolloClient.query({
      query: GET_TAX_SESSION_INFO,
      variables: {
        sessionHash,
        sessionId,
      },
    });
    return results?.data?.session;
  } catch (err) {
    if (process.env.NODE_ENV === 'test') {
      // TODO: Consider fixing linting error when editing file next
      // eslint-disable-next-line no-console
      console.log('Error fetching user data:', err);
    }
    return {};
  }
};

/**
 * Destructures the apolloClient and documentType from context and calls
 * fetchSessionData. Use only if graphql data is needed
 * inside `getInitialProps`. If a component needs graphql data, it
 * should fetch graphql data directly using `useQuery`.
 *
 * @param {object} ctx: Context object for the component, which in this case is used
 * to access the apolloClient.
 *
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default async function getTaxSessionData(ctx) {
  const {
    apolloClient,
    query: { sessionHash, sessionId },
  } = ctx;
  const session = await fetchTaxSessionData(
    apolloClient,
    sessionId,
    sessionHash
  );
  return session;
}
